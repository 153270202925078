//Note: injects jobTracker, rather than consuming a jobs object,
//because in my testing one-way binding does not seem to work when injected using $resolve
function QueueController($scope, jobEvents, jobTracker, $interval, $log, $location, $timeout, $q) {
  // TODO: Use services to keep track of last viewed job.
  this.initialized = false;

  this.checkingStatus = false;
  this.checkingStatusSuccess = false;
  this.checkingStatusError = false;

  let completionListener;
  let failedListener;
  let progressListener;
  let updateInterval;
  Object.defineProperty(this, 'jobBeingViewed', {
    get: () => this._id ? jobTracker.jobs.all[this._id] : {},
  });

  this.hasJob = () => this.jobBeingViewed && Object.keys(this.jobBeingViewed).length > 0;

  const clearListeners = () => {
    // Clear an existing listener
    if (completionListener) {
      completionListener();
    }

    if (failedListener) {
      failedListener();
    }

    if (progressListener) {
      progressListener();
    }

    if (updateInterval) {
      $interval.cancel(updateInterval);
    }
  };

  const setListeners = () => {
    if (this.jobBeingViewed) {
      if (completionListener) {
        completionListener();
      }

      if (failedListener) {
        failedListener();
      }

      if (progressListener) {
        progressListener();
      }

      completionListener = $scope.$on(
        `${jobEvents.eventPrefix}${jobEvents.events.annotation.completed}`, (event, rJob) => {
          if (rJob._id === this.jobBeingViewed._id) {
            $location.path('/results').search({ _id: rJob._id });
          }
        });

      failedListener = $scope.$on(
        `${jobEvents.eventPrefix}${jobEvents.events.annotation.failed}`, (event, rJob) => {
          if (rJob._id === this.jobBeingViewed._id) {
            $location.path('/queue/failed').search({ _id: rJob._id });
          }
        });
    }
  };

  // Note that for now we do not allow 'completed' type jobs in the queue
  // However, for now this is fine; every job that is changed to completed
  // during its time in the queue will have us redirected to the /results section
  // TODO: We need to clarify the role of the queue component
  // TODO: simplify this, so that queue routes all jobs, including results
  // by the passed type
  const typeChanged = (job) => {
    return this.jobType === 'all' ||
      (job.isCompleted() && this.jobType !== 'completed' ||
        job.isFailed() && this.jobType !== 'failed' ||
        job.isIncomplete() && this.jobType !== 'incomplete');
  };

  this.clearJobBeingViewed = () => {
    clearListeners();
    this._id = null;
    $location.search('_id', null);
  };

  this.updateRoutingState = (updatedJob) => {
    // We must update the _id to ensure this.jobBeingViewed is updated
    this._id = updatedJob._id;
    const _id = updatedJob._id;

    if (updatedJob.isDeleted()) {
      this.clearJobBeingViewed();
      return;
    }

    if (!typeChanged(updatedJob)) {
      $location.search({ _id });
      setListeners();
      return;
    }

    if (updatedJob.isCompleted()) {
      clearListeners();
      $location.path('/results').search({ _id });
      return;
    }

    if (updatedJob.isFailed()) {
      clearListeners();
      $location.path('/queue/failed').search({ _id });
      return;
    }

    if (updatedJob.isIncomplete()) {
      clearListeners();
      $location.path('/queue/incomplete').search({ _id });
      return;
    }
  };

  this.clear = this.clearJobBeingViewed;

  this.checkStatus = (job) => {
    this.checkingStatus = true;

    if (!job) {
      job = this.jobBeingViewed;
    }

    return job.$checkStatus().then((jResponse) => {
      this.checkingStatusSuccess = true;

      const [err, updatedJob] = jobTracker.trackJobUpdate(jResponse);
      if (err) {
        return $q.reject(err);
      }

      this.updateRoutingState(updatedJob);

      return updatedJob;
    }).catch((err) => {
      this.checkingStatusError = err;
      return err;
    }).finally(() => {
      $timeout(() => {
        this.checkingStatus = false;
        this.checkingStatusSuccess = false;
        // Keep error visible
      }, 250);
    });
  };

  this.updateJob = (id, showLoadingIndicator = true) => {
    if (!id) {
      $q.reject("Need id in updateJob");
      return;
    }

    if (showLoadingIndicator) {
      // We cannot pass a one-way bound job to the component from route resolver
      // and have reference changes propagate... annoying.
      // So we do this instead
      this.fetchingData = true;
    }

    // The only point here is to give jobTracker the latest info
    // jobBeingViewed will automatically contain the latest via reference-tracking
    // or if we choose to move away from that, can be updated using $scope.$on
    // to listen on jobTracker's jobUpdateEvent broadcast
    // We force updates to get latest info
    return jobTracker.initializeAsync().then(() => {
      // Force refresh ensure jobTracker has the latest data
      // in `all` and the appropriate sub-type (e.g. `incomplete`)
      return jobTracker.getOneAsync(id, true).finally(() => {
        this.fetchingData = false;
      });
    });
  };

  this.updateAndCheckStatus = (id, showLoadingIndicator = true) => {
    if (!id) {
      return;
    }

    this.updateJob(id, showLoadingIndicator).then((updatedJob) => {
      this.checkStatus(updatedJob);
    });
  };

  this.$onInit = () => {
    jobTracker.initializeAsync();
  };

  this.$onDestroy = () => {
    if (updateInterval) {
      $interval.cancel(updateInterval);
    }
  };

  this.$onChanges = (changesObject) => {
    if (!changesObject._id.currentValue) {
      this.clearJobBeingViewed();
    }

    if (changesObject._id && changesObject._id.currentValue && changesObject._id.currentValue !== changesObject._id.previousValue) {
      this.updateAndCheckStatus(changesObject._id.currentValue);

      if (updateInterval) {
        $interval.cancel(updateInterval);
      }

      updateInterval = $interval(() => {
        this.updateJob(changesObject._id.currentValue, false).then((updatedJob) => {
          this.updateRoutingState(updatedJob);
        });
      }, 15000);
    }
  };
}

angular.module('sq.jobs.queue.component',
  ['sq.jobs.tracker.service',
    'sq.jobs.chooser.component', 'sq.jobs.events.service',
    'sq.jobs.infoCard.component', 'sq.jobs.queue.status.component'])
  .component('sqJobQueue', {
    bindings: {
      jobType: '<',
      _id: '<id',
    },
    templateUrl: 'jobs/queue/jobs.queue.tpl.html',
    controller: QueueController,
  });
